import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import useLocalize from '../../../hooks/useLocalize';
import { renderPageSections } from '../renderPageSections';

const Page = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const pageProps = {
    id: data.sanityPage._id,
    metadata: { ...data.sanityPage._rawRoute, href },
    header: data.sanityPage._rawHeader,
    sections: data.sanityPage._rawSections,
    bubbleCtaSection: data.sanityPage._rawBubbleCtaSection,
  };

  return (
    <Layout
      locale={locale}
      sanityId={pageProps.id}
      {...pageProps.header}
      {...pageProps.metadata}
    >
      {renderPageSections(pageProps.sections)}
      {pageProps.bubbleCtaSection && (
        <BubbleCtaSection {...pageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.shape({ sanityPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

Page.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default Page;

export const query = graphql`
  query($slug: String) {
    sanityPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawHeader(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
      _id
    }
  }
`;
